import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { OnApproveData,OnApproveActions,CreateOrderData,CreateOrderActions} from '@paypal/paypal-js';
interface PaypalButtonProps {
  price: string;
}

const PaypalButton: React.FC<PaypalButtonProps> = ({ price }) => {
  function createOrder(data: CreateOrderData, actions: CreateOrderActions) {
    return fetch(" https://paypal-worker.benahmed-faycal.workers.dev/create/order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({'prix':price}),
    })
      .then((response) => response.json())
      .then((order) => {
        return order.id;
      });
  }
  function onApprove(data: OnApproveData, actions: OnApproveActions): Promise<void> {
    const url = " https://paypal-worker.benahmed-faycal.workers.dev/capture/order";
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ data })
    };

    return fetch(url, options)
      .then(response => response.json())
      .then(orderData => alert("mission accompli"));
  }


  const initialOptions = {
    clientId: "AW96yJLFyX4RZ5g5r35hkWrwUmsv2bMIlM0eEYv31n0dYIiCCjBLAiA77zPrQx5uJgZ6NNCa8dikqMSR",
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;
