import React from 'react';
import Blog from "./blog/Blog";
import DashBoard from "./dashboard/Dashboard";
import SignInSide from "./sign-in-side/SignInSide";
import Pricing from "./pricing/Pricing"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Blog />} />
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="/signin" element={<SignInSide />} />
                <Route path="/price" element={<Pricing />} />
            </Routes>
        </Router>
    );
}

export default App;
